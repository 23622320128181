@import "~antd/dist/antd.css";
@font-face {
  font-family: "Light";
  src:
    local("Franklin Gothic"),
    url("./fonts/fonnts.com-Franklin_Gothic_ATF.otf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Franklin Gothic", sans-serif;
  overflow-x: hidden;
}

.truncateText {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* START APPROFONDIMENTO STYLE (RESEARCH) */
#research table,
td {
  border: 1px solid black;
  padding: 3px;
  color: black;
}
#research p {
  color: black;
}
#research .tableHeader {
  background-color: black;
}
#research .tableHeader p {
  color: white;
}

#research .tdGray {
  background-color: rgb(175, 175, 175);
  color: black;
}
/* END APPROFONDIMENTO STYLE (RESEARCH) */
